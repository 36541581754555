p {
  color: #fff;
  font-size: 24px;
  max-width: 80%;
  margin: auto;
  margin-bottom: 40px;
}
.tiles {
  padding: 0 35px;
  margin-top: 60px;
}
/*# sourceMappingURL=src/views/Learn.css.map */