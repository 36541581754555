.LearnTile {
  padding: 22px 20px 1px 20px;
  user-select: none;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 20px;
  position: relative;
}
.LearnTile .details {
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-align: left;
}
.LearnTile .details .titleLesson {
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;
}
.LearnTile .details .descLesson {
  font-family: 'HK Grotesk Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
.LearnTile .percent {
  position: absolute;
  top: 22px;
  right: 28px;
}
.LearnTile .score {
  font-family: Neue Montreal;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
  text-align: left;
}
.LearnTile .score img {
  margin-right: 4px;
}
.LearnTile .illustration {
  margin-left: -20px;
  margin-top: -20px;
  width: calc(100% + 40px);
}
.LearnTile .cloud {
  position: absolute;
  top: 118px;
  left: -98px;
  width: 194px;
  height: auto;
}
.LearnTile .title {
  font-family: NanumGothic;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #fff;
  margin-top: 10px;
  text-align: left;
}
.LearnTile .desc {
  margin-top: 20px;
  font-family: 'HK Grotesk Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.03em;
  text-align: left;
}
.LearnTile.lock {
  background: #fff;
  box-shadow: 0px 18px 63px rgba(249,27,91,0.09), 0px 4.02054px 14.0719px rgba(249,27,91,0.054), 0px 1.19702px 4.18956px rgba(249,27,91,0.036);
}
.LearnTile.lock .title {
  font-family: NanumGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #828282;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 20px;
  opacity: 0.6;
}
.LearnTile.lock .locker {
  position: absolute;
  right: 38px;
  top: 18px;
  width: 23px;
  height: auto;
  opacity: 0.6;
}
.LearnTile.unlock {
  background: linear-gradient(180deg, rgba(255,83,121,0.5) 0%, #ff5379 100%);
  box-shadow: 0px 100px 80px rgba(255,33,123,0.04), 0px 41.7776px 33.4221px rgba(255,33,123,0.05), 0px 22.3363px 17.869px rgba(255,33,123,0.042), 0px 12.5216px 10.0172px rgba(255,33,123,0.035), 0px 6.6501px 5.32008px rgba(255,33,123,0.028), 0px 2.76726px 2.21381px rgba(255,33,123,0.02);
}
/*# sourceMappingURL=src/components/LearnTile.css.map */